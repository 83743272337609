<template>

  <b-card>
    <ValidationObserver ref="observer">
      <!-- form -->
      <b-form id="purchaseinboundForm" class="edit-form mt-2">

          <b-row>
            <b-col md="12">

              <div class="inner-card-title">基本信息</div>
            </b-col>
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="入库单编号"
                  label-for="inbound_no"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="inbound_no"
                    size="sm"
                    readonly
                    v-model="purchaseinbound.inbound_no"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="我方仓库"
                  label-for="warehouse_name"
                  label-size="sm"
                  class="mb-1 required"
              >
                <ValidationProvider rules="required" name="我方仓库" #default="{ errors }">
                  <b-form-input
                      id="warehouse_name"
                      size="sm"
                      v-b-modal.modal-select-warehouse
                      v-model="purchaseinbound.warehouse_name"
                      readonly
                      placeholder="点击搜索我方仓库"
                      :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="物流方式"
                  label-for="delivery_type"
                  label-size="sm"
                  class="mb-1 required"
              >
                <ValidationProvider rules="required" name="物流方式" #default="{ errors }">
                  <v-select
                      id="delivery_type"
                      :options="getCodeOptions('delivery_method_purchase')"
                      :clearable="true"
                      v-model="delivery_type"
                      @input="changeSelect('delivery_type',$event)"
                      class="select-size-sm"
                  >
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="运输方式"
                  label-for="transport_type"
                  label-size="sm"
                  class="mb-1 required"
              >
                <ValidationProvider rules="required" name="运输方式" #default="{ errors }">
                  <v-select
                      id="transport_type"
                      :options="getCodeOptions('transport_type')"
                      :clearable="true"
                      v-model="transport_type"
                      @input="changeSelect('transport_type',$event)"
                      class="select-size-sm"
                  >
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="发货时间"
                  label-for="delivery_time"
                  label-size="sm"
                  class="mb-1 required"
              >
                <ValidationProvider rules="required" name="发货时间" #default="{ errors }">
                  <flat-pickr
                      id="delivery_time"
                      v-model="delivery_time"
                      class="form-control"
                      :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
                <!--              <b-form-datepicker id="delivery_time" v-model="delivery_time" class="mb-2"></b-form-datepicker>-->
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="预计到达时间"
                  label-for="arrival_time"
                  label-size="sm"
                  class="mb-1 required"
              >
                <ValidationProvider rules="required" name="预计到达时间" #default="{ errors }">
                  <flat-pickr
                      id="arrival_time"
                      v-model="arrival_time"
                      :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
                      class="form-control"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="承运商"
                  label-for="carrier"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="carrier"
                    size="sm"
                    v-model="purchaseinbound.carrier"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="快递单号"
                  label-for="express_no"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="express_no"
                    size="sm"
                    v-model="purchaseinbound.express_no"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="司机信息"
                  label-for="driver_info"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="driver_info"
                    size="sm"
                    v-model="purchaseinbound.driver_info"
                />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                  label-cols="1"
                  label-cols-lg="1"
                  label="备注"
                  label-for="memo"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-textarea
                    id="memo"
                    v-model="purchaseinbound.memo"
                    rows="3"
                    max-rows="6"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <div class="inner-card-title">附件</div>
            </b-col>
            <b-col md="12">
              <b-form-group
                  label-cols="1"
                  label-cols-lg="1"
                  label="附件"
                  label-for="attach"
                  label-size="sm"
                  class="mb-1"
              >
                <attachment-upload v-if="purchaseinbound.loaded" :theme="'files'"
                                   :attachment_id="'attach'"
                                   :id="purchaseinbound.attach"
                                   :object_type="'purchase_inbound'"
                                   :object_id="purchaseinbound.inbound_id"
                                   @change="onUploaded"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <div class="inner-card-title">明细列表</div>
            </b-col>
            <b-col md="12">
              <purchase-inbound-item-list ref="itemList" :inbound_id="purchaseinbound.inbound_id" v-if="itemListShowFlag">

              </purchase-inbound-item-list>
            </b-col>
          </b-row>

      </b-form>
      <!--/ form -->
    </ValidationObserver>
    <b-modal
        id="modal-select-warehouse"
        ok-only
        ok-title="确认"
        @ok="onSelectWarehouse"
        cancel-title="取消"
        centered
        size="xl"
        title="选择我方仓库"
    >
      <warehouse-list
          ref="warehouseSelect" :type="1" v-on:table="fromChildren($event,'modal-select-warehouse')"
      >
      </warehouse-list>
    </b-modal>

    <b-col
        cols="12"
        class="mt-50"
    >
      <b-button
          variant="primary"
          class="mr-1"
          @click="save"
          v-if="!readonly"
      >
        保存
      </b-button>
      <b-button
          variant="outline-secondary"
          class="mr-1"
          @click="cancel"
      >
        取消
      </b-button>
      <b-button
          variant="success"
          class="mr-1"
          v-if="!readonly"
          @click="saveAndSubmitWarehouse"
      >
        保存并提交仓库
      </b-button>
    </b-col>
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BModal,
  BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onMounted, onUnmounted, ref, nextTick, reactive, toRefs } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import purchaseinboundStore from './purchaseinboundStore'
import { useRouter } from '@core/utils/utils'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'

// Notification
import { useToast } from 'vue-toastification/composition'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor, second } from '@core/utils/filter'
import WarehouseList from '@/views/apps/warehouse/warehouse-modal/WarehouseList'
import PurchaseInboundItemList from '@/views/apps/purchaseinbounditem/PurchaseInboundItemList'

export default {
  components: {
    AttachmentUpload,
    PurchaseInboundItemList,
    BFormDatepicker,
    WarehouseList,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    BModal,
    vSelect,
    useToast,
  },
  data() {
    return {
      // id: ref(0),
      purchaseinbound: {},
      // itemListShowFlag:false,
      delivery_type: '',
      transport_type: '',
      delivery_time: '',
      arrival_time: '',
    }
  },
  methods: {
    responsiveSet(key, value) {
      this.$set(this.purchaseinbound, key, value)
    },
    changeSelect(key, event) {
      this.purchaseinbound[key] = event == null ? undefined : event.value
    },
    saveItem() {
      let items = this.$refs.itemList.getList()
      let res = []
      for (let i = 0; i < items.length; i++) {
        let item = items[i]
        let response = store.dispatch('purchaseinbounditem/updateOrderItem', {
          itemid: item.inbounditem_id,
          issel: item.is_sel,
          qty: item.qty,
          inventorytype:item.inventory_type
        })
        res.push(response)
      }
      return res
    }
  },
  setup() {
    const toast = useToast()
    const {
      route,
      router
    } = useRouter()

    // Register module
    if (!store.hasModule('purchaseinbound')) store.registerModule('purchaseinbound', purchaseinboundStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('purchaseinbound')) store.unregisterModule('purchaseinbound')
    })

    //data
    const state = reactive({
      readonly:false,
    })
    const itemListShowFlag = ref(false)

    //method
    const onSelectWarehouse = function () {

      let data = this.$refs.warehouseSelect.getSelected()[0]
      this.responsiveSet('warehouse_id', data['warehouse_id'])
      this.responsiveSet('warehouse_name', data['warehouse_name'])
    }
    const edit = function () {
      let id = route.value.query.id || 0
      let orderId = route.value.query.order_id || 0
      state.readonly = route.value.query.readonly || false
      store.dispatch('purchaseinbound/edit', {
        id: id,
        orderId: orderId
      })
          .then(res => {
            if (res.data.code==1){
              toast.error(res.data.data)
              this.$router.push({ name: 'apps-purchaseinbound-list' })
            }
            this.purchaseinbound = res.data.data
            itemListShowFlag.value = true
            if (id !== 0) {
              this.delivery_type = getCode('delivery_method_purchase', this.purchaseinbound.delivery_type)
              this.transport_type = getCode('transport_type', this.purchaseinbound.transport_type)
              this.delivery_time = toTime(this.purchaseinbound.delivery_time)
              this.arrival_time = toTime(this.purchaseinbound.arrival_time)
            }
          })
    }
    const view = function () {
      store.dispatch('purchaseinbound/view', { id: this.id })
          .then(res => {
            this.purchaseinbound = res.data.data
          })
    }
    const onUploaded = function (key, value,result) {
      this.purchaseinbound[key] = result
    }

    const cancel = function () {
      router.go(-1)
    }

    const save = async function () {
      if (this.purchaseinbound.status !== 0) {
        toast.error('当前状态不允许保存!')
        return false
      }
      //子表字段校验
      let validateItem = await this.$refs.itemList.$refs.observerItemList.validate()

      let validate = await this.$refs.observer.validate()
      if (validateItem && validate) {
        let promises = this.saveItem()
        this.purchaseinbound.delivery_time = second(this.delivery_time)
        this.purchaseinbound.arrival_time = second(this.arrival_time)
        promises.push(store.dispatch('purchaseinbound/save', this.purchaseinbound))
        let resList = await Promise.all(promises)

        if (resList.filter(item => item.data.code === 1).length === 0) {
          toast.success('数据已保存!')
          await this.$router.push({ name: 'apps-purchaseinbound-list' })
        } else {
          for (let i = 0; i < resList.length; i++) {
            let res = resList[i]
            let prefix = res.config.url.indexOf('purchaseinbounditem') === -1 ? '表单数据' : `产品#${res.data.data.inbounditem_id}`
            if (res.data.code === 0) {
              toast.success(`${prefix}保存成功`)
            } else {
              toast.error(res.data.data)
            }
          }
          this.$refs.itemList.refetchData()
        }
      } else {
        toast.error('请正确填写字段')
      }
    }

    const saveAndSubmitWarehouse =async function (params) {
      if (this.purchaseinbound.status !== 0) {
        toast.error('当前状态不允许保存!')
        return false
      }
      //子表字段校验
      let validateItem = await this.$refs.itemList.$refs.observerItemList.validate()

      let validate = await this.$refs.observer.validate()
      if (validateItem && validate) {
        let promises = this.saveItem()
        this.purchaseinbound.delivery_time = second(this.delivery_time)
        this.purchaseinbound.arrival_time = second(this.arrival_time)
        promises.push(store.dispatch('purchaseinbound/save', this.purchaseinbound))
        let resList = await Promise.all(promises)

        if (resList.filter(item => item.data.code === 1).length === 0) {
          toast.success('数据已保存!')
          store.dispatch('purchaseinbound/submitwarehouse', { id: this.purchaseinbound.inbound_id })
              .then((res) => {
                if (res.status === 200) {
                  toast.success('提交成功')
                  this.$router.push({ name: 'apps-purchaseinbound-list' })
                }
              })

        } else {
          for (let i = 0; i < resList.length; i++) {
            let res = resList[i]
            let prefix = res.config.url.indexOf('purchaseinbounditem') === -1 ? '表单数据' : `产品#${res.data.data.inbounditem_id}`
            if (res.data.code === 0) {
              toast.success(`${prefix}保存成功`)
            } else {
              toast.error(res.data.data)
            }
          }
          this.$refs.itemList.refetchData()
        }
      } else {
        toast.error('请正确填写字段')
      }
    }

    const fromChildren = function (data,modal) {
      if (modal=='modal-select-warehouse'){
        this.responsiveSet('warehouse_id', data['warehouse_id'])
        this.responsiveSet('warehouse_name', data['warehouse_name'])
      }
      this.$bvModal.hide(modal)
    }

    return {
      ...toRefs(state),
      //method
      edit,
      view,
      cancel,
      save,
      onSelectWarehouse,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,

      itemListShowFlag,
      onUploaded,
      saveAndSubmitWarehouse,
      fromChildren,

    }
  },
  created() {
    this.edit()
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
